import "jquery"

import jquery from "jquery"
window.jQuery = jquery
window.$ = jquery

import "bootstrap"
require('bootstrap-select/js/bootstrap-select');

$.fn.selectpicker.Constructor.BootstrapVersion  = '4';

$(function() {
  $('.grant_dropdown_trigger').click(function() {
    $(this).toggleClass('open');
    $(this).next('.grant_dropdown_content_container').toggleClass('show');
  });

  $('.selectpicker').selectpicker();

  $('[name="application[resume]"]').change(e => {
    const $this = $(e.currentTarget)
    $this.siblings('span._placeholder').show()
    if ($this[0].files.length) {
      $this.siblings('span._placeholder').hide()
      $('#file_name').text(`${$this[0].files[0].name}`);
      $("#error-message-pdf").css('display', 'none');
    }
    const fileName = $this[0].files.length ? $this[0].files[0].name : null;
    const isPDF = fileName && fileName.toLowerCase().endsWith('.pdf');
    if (!isPDF) {
      $('#file_name').html('<span class="_placeholder green_text">Click to upload</span> or drag and drop');
      $this.siblings('span._placeholder').show()
      $("#error-message-pdf").css('display', 'block');
      $this.val('');
    }
  })

  $('#inputGpa').on('input', function() {
    if($(this).val() > 4.00) {
      let newValue = Math.min(parseFloat($(this).val()), 4.00);
      $(this).val(newValue.toFixed(2));
    }
  });
  $('.collage .dropdown-menu').css('max-width', '698px');
  $('.dropdown-menu .inner .show').addClass('mt-0 mb-0');

  if ($("#inputCurrentStatus").length > 0){
    document.getElementById('inputCurrentStatus').addEventListener('change', function () {
      var additionalFieldContainer = document.getElementById('additionalFieldContainerHide');

      if (this.value === 'Undergraduate Student' || this.value === "Graduate Student - Master's Degree Program" || this.value === "Graduate Student – PhD program") {
          additionalFieldContainer.classList.remove('d-none');
      } else {
          additionalFieldContainer.classList.add('d-none');
      }
    });

    document.getElementById('inputCurrentStatus').addEventListener('change', function () {
      var profileLinkContainer = document.getElementById('profileLinkContainerhide');

      if (['Postdoctoral Fellow', 'Other', 'Assistant Professor'].includes(this.value)) {
        profileLinkContainer.classList.remove('d-none');
      } else {
        profileLinkContainer.classList.add('d-none');
      }
    });

    document.getElementById('inputCurrentStatus').addEventListener('change', function () {
      var profileLinkContainer = document.getElementById('majorFieldContainerMain');

      if (['Undergraduate Student'].includes(this.value)) {
        profileLinkContainer.classList.remove('d-none');
      } else {
        profileLinkContainer.classList.add('d-none');
      }
    });

    document.getElementById('inputCurrentStatus').addEventListener('change', function () {
      var profileLinkContainer = document.getElementById('educationOpportunitieshide');

      if (['Undergraduate Student', "Graduate Student - Master's Degree Program", "Graduate Student – PhD program", 'Postdoctoral Fellow', 'Assistant Professor'].includes(this.value)) {
        profileLinkContainer.classList.remove('d-none');
      } else {
        profileLinkContainer.classList.add('d-none');
      }
    });

    document.getElementById('inputCurrentStatus').addEventListener('value', function () {
      var profileLinkContainer = document.getElementById('generalTermshide');

      if (true) {
        profileLinkContainer.classList.remove('d-none');
      } else {
        profileLinkContainer.classList.add('d-none');
      }
    });

    document.getElementById('inputCurrentStatus').addEventListener('change', function () {
      var profileLinkContainer = document.getElementById('universityInventionHide');

      if (['Postdoctoral Fellow', 'Assistant Professor'].includes(this.value)) {
        profileLinkContainer.classList.remove('d-none');
      } else {
        profileLinkContainer.classList.add('d-none');
      }
    });

    document.getElementById('inputCurrentStatus').addEventListener('change', function () {
      var profileLinkContainer = document.getElementById('provisionalPatentApplicationsHide');

      if (['Other'].includes(this.value)) {
        profileLinkContainer.classList.remove('d-none');
      } else {
        profileLinkContainer.classList.add('d-none');
      }
    });


    document.getElementById('inputCurrentStatus').addEventListener('change', function () {
      var profileLinkContainer = document.getElementById('bioEntrepreneurshiphide');

      if (["Undergraduate Student", "Graduate Student - Master's Degree Program", "Graduate Student – PhD program", "Postdoctoral Fellow", "Other"].includes(this.value)) {
        profileLinkContainer.classList.remove('d-none');
      } else {
        profileLinkContainer.classList.add('d-none');
      }
    });

    document.getElementById('inputCurrentStatus').addEventListener('change', function () {
      var profileLinkContainer = document.getElementById('entrepreneurshipEducationOpportunitieshide');

      if (["Assistant Professor"].includes(this.value)) {
        profileLinkContainer.classList.remove('d-none');
      } else {
        profileLinkContainer.classList.add('d-none');
      }
    });

    document.getElementById('inputCurrentStatus').addEventListener('change', function () {
      var profileLinkContainer = document.getElementById('workshophide');

      if (["Undergraduate Student", "Graduate Student - Master's Degree Program", "Graduate Student – PhD program", "Postdoctoral Fellow", "Assistant Professor"].includes(this.value)) {
        profileLinkContainer.classList.remove('d-none');
      } else {
        profileLinkContainer.classList.add('d-none');
      }
    });

    document.getElementById('inputCurrentStatus').addEventListener('change', function () {
      var profileLinkContainer = document.getElementById('organizationhide');

      if (["Other"].includes(this.value)) {
        profileLinkContainer.classList.remove('d-none');
      } else {
        profileLinkContainer.classList.add('d-none');
      }
    });

    document.getElementById('inputCurrentStatus').addEventListener('change', function () {
      if (["Early Career Faculty", "Mid / Senior Faculty"].includes(this.value)) {
        $('.college_input_container').addClass('d-none').removeClass('d-flex');
        $('.college_select').prop('required', false);
        $('.faculty_input_container').removeClass('d-none').addClass('d-flex');
        $('.faculty_college_input').prop('required', true);
        $('.faculty_input_container .create_job_form_heading').html("<span class='red_asteriksk'>*</span> Please provide the university and/or research institute or center where you have your primary faculty appointment and conduct your research")
      } else if (["Postdoctoral Fellow"].includes(this.value)) {
        $('.college_input_container').addClass('d-none').removeClass('d-flex');
        $('.college_select').prop('required', false);
        $('.faculty_input_container').removeClass('d-none').addClass('d-flex');
        $('.faculty_college_input').prop('required', true);
        $('.faculty_input_container .create_job_form_heading').html("<span class='red_asteriksk'>*</span> Please provide the university and/or research institute or center where you currently conduct your research")
      } else {
        $('.college_input_container').removeClass('d-none').addClass('d-flex');
        $('.college_select').prop('required', true);
        $('.faculty_input_container').addClass('d-none').removeClass('d-flex');
        $('.faculty_college_input').prop('required', false);
      }
    });
  }

  document.getElementsByName('application[biotechnology]').forEach(function (radio) {
    radio.addEventListener('change', function () {
      var biotechIdeaContainer = document.getElementById('generalTermshide');

      // Show/hide the biotechnology idea description field based on the selected option
      if (this.value === 'yes') {
        biotechIdeaContainer.classList.remove('d-none');
      } else {
        biotechIdeaContainer.classList.add('d-none');
      }
    });
  });

  $('.affirming_checkbox').on('change', function() {
    if(this.checked) {
      $(':input[type="submit"]').prop('disabled', false);
    } else {
      $(':input[type="submit"]').prop('disabled', true);
    }
  });
})
